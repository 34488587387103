import { StorageService, WithDestroy } from '@aex/ngx-toolbox';
import { CustomerUserService, IApiCommonFilters, IFilterQuery, IGridColumn, IGridData, IPagination, IRecord, ISortQuery, IView, SORT_ORDER } from '@aex/shared/common-lib';
import { HttpResponse } from '@angular/common/http';
import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { filterIcon, searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { get } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { IGridColumnToDisplay } from '../components/shared/data-grid/data-grid.types';
import { SaveViewDialogComponent } from '../components/shared/save-view-dialog/save-view-dialog.component';
import { GridModalService } from '../services/grid-modal.service';
import { GridRoutingService } from '../services/grid-routing.service';
import { getKeyPathFromColumnObject } from '../util/common.utils';
import { PAGE_SIZE_LEVEL_1, PAGE_SIZE_LEVEL_2, PAGE_SIZE_LEVEL_3, PAGE_SIZE_LEVEL_4, svgImageHeight, svgImageWidth } from './constant';
import { SavingStates, ViewDialogActions } from './enum';

@Directive()
export abstract class BaseDataGrid extends WithDestroy() {
	public readonly infoIcon: string = 'assets/img/portal/grey-info.svg';
	public readonly arrowDownIcon: string = 'assets/img/portal/arrow-down.svg';
	public readonly downloadIcon: string = 'assets/img/portal/download.svg';
	public readonly searchIcon: string = 'assets/img/portal/search-icon.svg';
	public readonly filterIcon: SVGIcon = filterIcon;
	public readonly kendoSearchIcon: SVGIcon = searchIcon;
	public filtersOpened: boolean = false;
	public hideQuickTools: boolean = false;
	private serviceStatus: string;
	private typeId: number;
	public propertyToNavigate: string;
	public imageWidth: string = svgImageWidth;
	public imageHeight: string = svgImageHeight;

	public pageSize: number = PAGE_SIZE_LEVEL_4;
	public page: number = 1;
	public skip: number = 0;
	public totalCount: number;
	public sort: SortDescriptor[];
	public readonly sizes: number[] = [PAGE_SIZE_LEVEL_1, PAGE_SIZE_LEVEL_2, PAGE_SIZE_LEVEL_3, PAGE_SIZE_LEVEL_4];
	public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	public filters: FilterExpression[];
	public filterSettings: DropDownFilterSettings = {
		caseSensitive: false,
		operator: 'contains',
	};
	public filterMappedQuery: string = '';
	private readonly customerViewKey: string = 'customer_views'


	public gridViewModal: boolean = false;
	public dataGridColumns: IGridColumn[];
	public selectedGridColumns: IGridColumn[];
	public columnHeadings: string[];
	public viewNameAdd: boolean = false;
	public selectedView: IView;
	public savingState?: SavingStates;
	public searchQuery: string = '';
	// This was done to resolve API edge cases for task component where not just the keyword but also the object property should be in the search_string that is to be filtered.
	// Issue: since searchQuery was binded to kendo-textbox, the said object property was also being displayed in the textbox.
	// Solution: separate searchQuery and displaySearchQuery functionality.
	public displaySearchQuery: string = '';
	public savedViews: IView[];
	public sortField: string;
	public sortOrder: SORT_ORDER;
	public viewNameChange: string = '';
	public gridData: IGridData;
	public data: IRecord[];

	protected gridViewKey: string;
	protected preDefinedViews: IView[];
	protected abstract loadData(): void;

	protected constructor(
		protected baseRoute: ActivatedRoute,
		protected baseRouter: Router,
		protected baseGridRoutingService: GridRoutingService,
		protected baseStorageService: StorageService,
		gridViewKey: string,
		preDefinedViews: IView[],
		public toasterService: ToastrService,
		protected gridModalService: GridModalService,
		protected dialogService?: DialogService,
		protected customerUserService?: CustomerUserService,
	) {
		super();
		this.baseRoute = baseRoute;
		this.baseRouter = baseRouter;
		this.baseGridRoutingService = baseGridRoutingService;
		this.baseStorageService = baseStorageService;
		this.gridViewKey = gridViewKey;
		this.preDefinedViews = preDefinedViews;
		this.dialogService = dialogService;
		this.gridModalService = gridModalService;

		this.gridModalService.setBaseDataGridRef(this);

		baseRoute.queryParams.subscribe((params) => {
			if (params['status'])
				this.serviceStatus = params['status'];
			if (params['type_id'])
				this.typeId = Number(params['type_id'])

			this.page = Number(params['page']) || this.page;
			this.pageSize = Number(params['pageSize']) || this.pageSize;
		});
		this.noZombie(this.gridModalService.savingStateStream).subscribe((stream) => this.savingState = stream);
		this.noZombie(baseGridRoutingService.gridViewModalStream).subscribe((data) => {
			this.gridViewModal = data;
			if (data)
				this.dialogService.open({
					title: 'Please Confirm',
					content: SaveViewDialogComponent,
					width: '500px',
					actionsLayout: 'stretched',
				});
		});
	}

	public onAfterValueChange(value: string, isForTask: boolean = false): void {
		this.searchQuery = value;
		if (isForTask)
			this.displaySearchQuery = value;
		this.resetPagination();
		this.gridModalService.setSavingState(SavingStates.BOTH);
		this.updateNavigateState(false);
		this.loadData()
	}

	public get pagination(): IPagination {
		return {
			count: this.pageSize,
			page: this.page,
			pageSize: this.pageSize,
		};
	}

	public get sortQuery(): ISortQuery {
		return {
			sort_field: this.sortField,
			sort_order: this.sortOrder,
		};
	}

	protected get filterQuery(): IFilterQuery {
		const baseQuery = {
			search_string: this.searchQuery,
		};

		const additionalQuery = this.gridViewKey === this.customerViewKey
			? { service_status: this.serviceStatus }
			: { work_order_status: this.serviceStatus, work_order_type_id: this.typeId };

		return { ...baseQuery, ...additionalQuery };
	}

	public get canEdit(): Observable<boolean> {
		return this.gridModalService.canEditStream;
	}

	public get canSave(): Observable<boolean> {
		return this.gridModalService.canSaveStream;
	}

	public get isViewPredefined(): boolean {
		return this.preDefinedViews.some((view) => view.name.toLowerCase() === this.selectedView.name.toLowerCase());
	}

	public updateNavigateState(state: boolean): void {
		this.baseGridRoutingService.shouldNavigateState = state;
	}

	public updateGridViewModalState(state: boolean): void {
		this.baseGridRoutingService.gridViewModalState = state;
	}

	public onViewModalAction(action: string): void {
		this.updateGridViewModalState(false);
		switch (action) {
			case ViewDialogActions.SAVE:
				this.saveView();
				break;
			case ViewDialogActions.UPDATE:
				this.updateView();
				this.updateNavigateState(true);
				break;
			case ViewDialogActions.DONT_SAVE:
				this.baseGridRoutingService.shouldNavigateState = true;
				this.baseRouter.navigate([this.baseGridRoutingService.intendedUrlState]).then();
				break;
			default:
				break;
		}
	}

	public saveView(): void {
		if (this.viewNameAdd)
			this.onEnterKeyPressed();
		else
			this.viewNameAdd = true;
	}

	public updateView(): void {
		this.selectedView = {
			...this.selectedView,
			searchQuery: this.searchQuery,
			selectedColumns: this.selectedGridColumns.map((column) => getKeyPathFromColumnObject(column)),
			selectedFilters: this.filterValue,
		};

		this.savedViews = this.savedViews.map((view) => {
			if (view.name === this.selectedView.name)
				return this.selectedView;
			else
				return view;
		});

		this.gridModalService.setSavingState(null);

		this.persistViews();
	}

	public persistViews(): void {
		this.baseStorageService.store(this.gridViewKey, JSON.stringify(this.savedViews), false, false);
	}

	public fetchAndSetViews(): void {
		let views = this.baseStorageService.retrieveObject<IView[]>(this.gridViewKey, false);
		if (Array.isArray(views) && views.length)
			this.savedViews = views;
		else {
			this.savedViews = this.preDefinedViews;
			this.persistViews();
		}
	}

	public fetchData(_: IApiCommonFilters): void {
		// This is going to be overridden
	}

	public refreshRouteWithQueryParams(): void {
		this.baseRouter
			.navigate([], {
				relativeTo: this.baseRoute,
				queryParams: {
					...this.baseRoute.snapshot.queryParams,
					page: this.pagination.page,
					pageSize: this.pagination.pageSize,
					sortField: this.sortQuery.sort_field,
					sortOrder: this.sortQuery.sort_order,
				},
			})
			.then(() => {
				this.fetchData({ ...this.pagination, ...this.sortQuery, ...this.filterQuery, search_string: this.filterMappedQuery });
			});
	}

	public dataSerializer(items: IRecord[], gridColumns: IGridColumn[]): IRecord[] {
		return items.map((item) => {
			return gridColumns.reduce((accumulator, column) => {
				const keyPath = getKeyPathFromColumnObject(column);
				const fieldValue = String(get(item, keyPath));

				if (fieldValue)
					accumulator[keyPath] = fieldValue;

				return accumulator;
			}, {} as IRecord);
		});
	}

	public onSort(sortQuery: ISortQuery): void {
		this.sortField = sortQuery.sort_field;
		this.sortOrder = sortQuery.sort_order;
		this.refreshRouteWithQueryParams();
	}

	public onPaginate(pagination: IPagination): void {
		this.page = pagination.page;
		this.pageSize = pagination.count;

		this.refreshRouteWithQueryParams();
	}

	public processGridDataResponse(gridData: IGridData): void {
		this.gridData = gridData;

		this.data = this.dataSerializer(this.gridData.items, this.dataGridColumns);

		if (this.data[0])
			this.sort = Object.keys(this.data[0]).map((field) => {
				let isSelectedField = false;
				if (this.sortField) {
					const splitSortField = this.sortField.split('.keyword')[0];
					isSelectedField = splitSortField === field;
				}
				return ({ field: field, dir: isSelectedField ? this.sortOrder: SORT_ORDER.ASC });
			});

		this.totalCount = gridData.total;
	}

	public applyFilter(value: CompositeFilterDescriptor): void {
		this.filterValue = value;

		const filter = this.filterQuery;

		filter.search_string = value.filters
			.map((val) => {
				if ('field' in val && 'value' in val)
					return `${val.field}: ${val.value}`;
				else
					return '';
			})
			.filter(Boolean)
			.join(' AND ');

		this.filterMappedQuery = filter.search_string;
		this.updateSavingState();
		this.updateNavigateState(false);

		this.fetchData({ ...filter, ...this.sortQuery, ...this.pagination });
	}

	public applyView(viewName: string): void {
		this.gridModalService.setSavingState(null);

		let views = this.baseStorageService.retrieveObject<IView[]>(this.gridViewKey, false);

		let view = views.find((view) => view.name.toLowerCase() === viewName.toLowerCase());

		if (view) {
			this.selectedView = view;
			this.filterValue = view.selectedFilters;
			this.columnHeadings = view.selectedColumns;
			this.filterQuery.search_string = view.searchQuery;
			this.searchQuery = view.searchQuery;
			if (!this.isPredefinedView(view.name))
				this.displaySearchQuery = view.searchQuery;
			else
				this.displaySearchQuery = '';
			this.updateSelectedColumns();
			this.fetchData({ ...this.filterQuery, ...this.sortQuery, ...this.pagination });
		}
	}

	protected isPredefinedView(viewName: string): boolean {
		return this.preDefinedViews.some(predefinedView =>
				predefinedView.name.toLowerCase() === viewName.toLowerCase(),
		);
	}

	public updateSelectedColumns(): void {
		const selectedColumns: [number, IGridColumn][] = [];

		this.dataGridColumns.forEach((column) => {
			const keyPath = getKeyPathFromColumnObject(column);

			const isSelected = this.columnHeadings.findIndex((heading) => heading === keyPath || heading === column.heading);

			if (isSelected !== -1)
				selectedColumns.push([isSelected, column]);
		});

		selectedColumns.sort((a, b) => a[0] - b[0]);

		this.selectedGridColumns = selectedColumns.map((column) => column[1]);
	}

	public deleteView(viewName: string): void {
		this.savedViews = this.savedViews.filter((view) => view.name.toLowerCase() !== viewName.toLowerCase());

		this.persistViews();
	}

	public addView(view: IView): void {
		this.savedViews = [...this.savedViews, view];

		this.persistViews();
	}

	public onEscKeyPressed(_: Event): void {
		this.viewNameAdd = false;
		this.viewNameChange = '';
	}

	public onEnterKeyPressed(): void {
		const isViewExist = this.savedViews?.find((view) => view.name.toLowerCase() === this.viewNameChange.toLowerCase());

		if (isViewExist) {
			this.toasterService.error('View name already exists');
			return;
		}

		const newView: IView = {
			name: this.viewNameChange,
			searchQuery: this.searchQuery,
			selectedColumns: this.selectedGridColumns.map((column) => getKeyPathFromColumnObject(column)),
			selectedFilters: this.filterValue,
			removable: true,
		};

		this.addView(newView);

		this.selectedView = newView;

		this.viewNameAdd = false;

		this.gridModalService.setSavingState(null);

		this.viewNameChange = '';

		this.updateNavigateState(true);
	}

	public setDataGridColumns(response: HttpResponse<IGridColumn[]>): void {
		this.dataGridColumns = response.body;

		this.updateSelectedColumns();

		const filtersMap: FilterExpression[] = this.dataGridColumns.map((column) => {
			return {
				field: `${column.field}.${column.sub_field}`,
				title: column.heading,
				editor: 'string',
			};
		});

		filtersMap.sort((a, b) => a.title.localeCompare(b.title));

		this.filters = filtersMap;
	}

	public updateSavingState(): void {
		if (this.isViewPredefined)
			this.gridModalService.setSavingState(SavingStates.ADD);
		else
			this.gridModalService.setSavingState(SavingStates.BOTH);
	}

	public handleColumnsChange(event: IGridColumn[]): void {
		this.selectedGridColumns = event;
		this.updateSavingState();
		this.updateNavigateState(false);
	}

	public toggleQuickTools(): void {
		this.hideQuickTools = !this.hideQuickTools;
	}

	public toggleFiltersPopup(show?: boolean): void {
		this.filtersOpened = show ?? !this.filtersOpened;
	}

	public gridStateChange(event: { columns: IGridColumnToDisplay[]; selectedColumns: IGridColumn[] }): void {
		const { columns, selectedColumns } = event;

		this.columnHeadings = columns.map((el) => el.name);
		this.selectedGridColumns = selectedColumns;
		this.updateSavingState();
		this.updateNavigateState(false);
	}

	public closeFilterDropdown(clickedElement: HTMLElement): void {
		const isInsideButton = clickedElement.closest('[data-testid="filter-dropdown-button"]');
		const isInsidePopup = clickedElement.closest('[id="listing-filters-container"]');
		const isRemoveFilterIcon = clickedElement.closest('button[title="Remove"]');

		const shouldCloseFilters = !isInsideButton && !isInsidePopup && !isRemoveFilterIcon;

		if (shouldCloseFilters)
			this.filtersOpened = false;
	}

	public removeSaveViewTextbox(clickedElement: HTMLElement): void {
		const isInsideTextbox = clickedElement.closest('[data-testid="save-view-textbox"]');
		const isInsideSaveButton = clickedElement.closest('[data-testid="save-view-button"]');

		if (!isInsideTextbox && !isInsideSaveButton)
			this.viewNameAdd = false;
	}

	public resetPagination(): void {
		this.page = 1;
		this.pageSize = PAGE_SIZE_LEVEL_4;
	}


	@HostListener('document:click', ['$event'])
	public onClick(event: MouseEvent): void {
		const clickedElement = event.target as HTMLElement;
		this.closeFilterDropdown(clickedElement);
		this.removeSaveViewTextbox(clickedElement);
	}
}
