import { StorageService } from '@aex/ngx-toolbox';
import { SearchService } from '@aex/shared/apis';
import { IApiCommonFilters, ITaskRecord, IView, PORTAL } from '@aex/shared/common-lib';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { saveAs } from '@progress/kendo-file-saver';
import { ToastrService } from 'ngx-toastr';
import { DataGridComponent } from '../../components/shared/data-grid/data-grid.component';
import { GridModalService } from '../../services/grid-modal.service';
import { GridRoutingService } from '../../services/grid-routing.service';
import { BaseDataGrid } from '../../shared/base-data-grid';

const EXPORT_FILE_NAME = 'Devices.csv';
// To be used later
// const PROPERTY_TO_NAVIGATE = 'service.id';

const DEFAULT_SELECTED_FILTER = {
	editor: 'string',
	field: 'area.area_status',
	operator: 'eq',
	title: 'Area: Area Status',
};

const PREDEFINED_VIEWS: IView[] = [
	{
		selectedColumns: [
			'customer.full_name',
			'device_asset_attributes.status',
			'premise.full_address',
			'isp_product.status', 'isp_product.name',
			'area.area_status',
			'area.name',
		],
		name: 'All',
		searchQuery: '',
		selectedFilters: {
			filters: [
				DEFAULT_SELECTED_FILTER,
			],
			logic: 'and',
		},
		removable: false,
	},
	{
		selectedColumns: [
			'Isp Product: Name',
			'Service: Status',
			'Customer: Full Name',
			'Device Field: Status',
			'Premise: Full Address',
			'Isp Product: Status',
			'Area: Area Status',
			'Area: Name',
		],
		name: 'Unassigned',
		searchQuery: '',
		selectedFilters: {
			filters: [
				DEFAULT_SELECTED_FILTER,
			],
			logic: 'and',
		},
		removable: false,
	},
	{
		selectedColumns: ['Premise: Full Address', 'Customer: Full Name', 'Device Field: Status', 'Isp Product: Status', 'Isp Product: Name', 'Area: Area Status', 'Area: Name'],
		name: 'Up',
		searchQuery: '',
		selectedFilters: {
			filters: [
				DEFAULT_SELECTED_FILTER,
			],
			logic: 'and',
		},
		removable: false,
	},
	{
		selectedColumns: ['Premise: Full Address', 'Customer: Full Name', 'Device Field: Status', 'Isp Product: Status', 'Isp Product: Name', 'Area: Area Status', 'Area: Name'],
		name: 'Down',
		searchQuery: '',
		selectedFilters: {
			filters: [
				DEFAULT_SELECTED_FILTER,
			],
			logic: 'and',
		},
		removable: false,
	},
	{
		selectedColumns: ['Premise: Full Address', 'Customer: Full Name', 'Device Field: Status', 'Isp Product: Status', 'Isp Product: Name', 'Area: Area Status', 'Area: Name'],
		name: 'Warning',
		searchQuery: '',
		selectedFilters: {
			filters: [
				DEFAULT_SELECTED_FILTER,
			],
			logic: 'and',
		},
		removable: false,
	},
];

const DEVICES_VIEWS_KEY = 'devices_views';

// To be used later
export const DEFAULT_VIEW = PREDEFINED_VIEWS[0];


@Component({
	selector: 'app-devices',
	templateUrl: './devices.component.html',
})
export class DevicesComponent extends BaseDataGrid {
	@ViewChild('filterAnchor', { read: ElementRef }) public anchor: ElementRef;
	@ViewChild(DataGridComponent) public dataGridComponent: DataGridComponent;

	public highlightedHeading: string = 'Customer: Full Name';

	constructor(
		protected readonly route: ActivatedRoute,
		protected readonly router: Router,
		private readonly searchService: SearchService,
		protected readonly gridRoutingService: GridRoutingService,
		protected readonly storageService: StorageService,
		public toastrService: ToastrService,
		protected gridModalService: GridModalService,
		protected dialogService: DialogService,
	) {
		super(route, router, gridRoutingService, storageService, DEVICES_VIEWS_KEY, PREDEFINED_VIEWS, toastrService, gridModalService, dialogService);
		this.selectedView = DEFAULT_VIEW;
	}

	public exportData(): void {
		this.searchService.exportCustomerGridData(this.selectedGridColumns).subscribe((res) => {
			const dataURI = 'data:text/csv;charset=utf-8,' + encodeURIComponent(res);
			saveAs(dataURI, EXPORT_FILE_NAME);
		});
	}

	public goToCustomerDetail(value: ITaskRecord): void {
		const data = this.data.find((el) => el[this.propertyToNavigate] === value[this.propertyToNavigate]);

		if (data) {
			const customerId = data[this.propertyToNavigate];
			this.router.navigate([PORTAL.customerPage.name, customerId]).then();
		}
	}

	protected loadData(): void {
		this.searchService
			.getAllCustomers({
				...this.pagination,
				...this.sortQuery,
				...this.filterQuery,
			})
			.subscribe((gridDataRes) => {
				this.processGridDataResponse(gridDataRes.body);
			});
	}

	public fetchData(body: IApiCommonFilters): void {
		this.searchService.getAllCustomers(body).subscribe((response) => {
			this.processGridDataResponse(response.body);
		});
	}
}
