import { AexNgToolboxModule, DEFAULT_LOADER_CONFIG, MockModule, NavigationService, TOASTER_CONFIG } from '@aex/ngx-toolbox';
import { ReportsModule } from '@aex/reports';
import { ProductService } from '@aex/shared/apis';
import { CommonLibModule, ENVIRONMENT_CONFIG_TOKEN, IEnvironment } from '@aex/shared/common-lib';
import { AuthService, ConfigService, DbService } from '@aex/shared/root-services';
import { CommonModule, NgOptimizedImageModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import 'hammerjs';
import { merge } from 'lodash';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { environment } from '../environments/environment';
import { MockRegistrations } from '../mock/mock';
import { PORTAL_APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutomatedInsightsComponent } from './automated-insights/automated-insights.component';
import { HeaderComponent } from './components/header/header.component';
import { HelpAndSupportMenuComponent } from './components/header/help-n-support/help-n-support.component';
import { PortalSettingsModalComponent } from './components/header/portal-settings-modal/portal-settings-modal.component';
import { UserNavigationMenuComponent } from './components/header/user-navigation/user-navigation.component';
import { DonutChartComponent } from './components/shared/donut-chart/donut-chart.component';
import { SharedModule } from './components/shared/shared.module';
import { TabComponent } from './components/shared/tab/tab.component';
import { KendoModule } from './kendo.module';
import { AuthModule } from './module/auth/auth.module';
import { DashboardComponent } from './module/dashboard/dashboard.component';
import { CancellationsTabComponent } from './module/dashboard/tabarea/cancellations-tab/cancellations-tab.component';
import { ChangesTabComponent } from './module/dashboard/tabarea/changes-tab/changes-tab.component';
import { NewOrdersTabComponent } from './module/dashboard/tabarea/new-orders-tab/new-orders-tab.component';
import { OthersTabComponent } from './module/dashboard/tabarea/others-tab/others-tab.component';
import { ReportChartComponent } from './module/dashboard/tabarea/report-chart/report-chart.component';
import { ServiceSubTabComponent } from './module/dashboard/tabarea/services-tab/service-sub-tab/service-sub-tab.component';
import { ServicesTabComponent } from './module/dashboard/tabarea/services-tab/services-tab.component';
import { SupportTabComponent } from './module/dashboard/tabarea/support-tab/support-tab.component';
import { UnassignedDevicesTabComponent } from './module/dashboard/tabarea/unassigned-devices-tab/unassigned-devices-tab.component';
import { WorkOrderSubTabComponent } from './module/dashboard/tabarea/workorder-sub-tab/work-order-sub-tab.component';
import { DevicesComponent } from './module/devices/devices.component';
import { FAQPanelContentComponent } from './module/faq/faq-panel-content.component';
import { FaqComponent } from './module/faq/faq.component';
import { LayoutComponent } from './module/layout/layout.component';
import { ManageProfileComponent } from './module/profile/manage-profile.component';
import { PortalAppConfigLoaderService } from './root-services/portal-app-config-loader.service';
import { PortalAuthService } from './root-services/portal-auth.service';
import { PortalConfigService } from './root-services/portal-config.service';
import { PortalAuthInterceptor } from './shared/portal-auth-interceptor';
import { PortalErrorInterceptor } from './shared/portal-error-interceptor';

export const ENV_CONFIG: Partial<IEnvironment> = environment;

const PORTAL_LOADER_CONFIG = merge(DEFAULT_LOADER_CONFIG, { fgsType: SPINNER.fadingCircle, fgsColor: 'white', bgsType: SPINNER.fadingCircle, bgsColor: 'white', pbColor: 'white' });

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		UserNavigationMenuComponent,
		PortalSettingsModalComponent,
		HelpAndSupportMenuComponent,
		LayoutComponent,
		DashboardComponent,
		TabComponent,
		ServicesTabComponent,
		ServiceSubTabComponent,
		ChangesTabComponent,
		NewOrdersTabComponent,
		WorkOrderSubTabComponent,
		UnassignedDevicesTabComponent,
		DonutChartComponent,
		CancellationsTabComponent,
		SupportTabComponent,
		OthersTabComponent,
		ManageProfileComponent,
		FaqComponent,
		FAQPanelContentComponent,
		DevicesComponent,
		ReportChartComponent,
		AutomatedInsightsComponent,
	],
	imports: [
		RouterModule.forRoot(PORTAL_APP_ROUTES),
		HttpClientModule,
		...KendoModule,
		CommonModule,
		AuthModule,
		BadgeModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CarouselModule,
		ButtonModule,
		AexNgToolboxModule.forRoot(),
		BrowserAnimationsModule,
		MockModule.forRoot({
			enabled: environment.mocksEnabled,
			serverUrls: [environment.ispServerUrl, environment.fnoServerUrl, environment.securityServiceUrl, environment.paymentServerUrl, environment.purchaseOrderServerUrl],
		}),
		NgxUiLoaderModule.forRoot(PORTAL_LOADER_CONFIG),
		ToastrModule.forRoot(TOASTER_CONFIG),
		ToastContainerModule,
		NgOptimizedImageModule,
		SharedModule,
		CommonLibModule,
		ReportsModule,
	],
	providers: [
		ProductService,
		{
			provide: AuthService,
			useClass: PortalAuthService,
		},
		NavigationService,
		{
			provide: ENVIRONMENT_CONFIG_TOKEN,
			useValue: ENV_CONFIG,
		},
		{
			provide: ConfigService,
			useClass: PortalConfigService,
		},
		PortalAppConfigLoaderService,
		{
			provide: APP_INITIALIZER,
			deps: [PortalAppConfigLoaderService],
			useFactory: (startUp: PortalAppConfigLoaderService) => (): Promise<void> => startUp.loadAppConfig().toPromise(),
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: PortalAuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: PortalErrorInterceptor,
			multi: true,
		},
		DbService,
		{
			provide: APP_INITIALIZER,
			deps: [DbService, MockRegistrations],
			//eslint-disable-next-line @typescript-eslint/explicit-function-return-type
			useFactory: (db: DbService) => () => db.initDb().toPromise(),
			multi: true,
		},
		ProductService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
