import { NavigationService, WithDestroy } from '@aex/ngx-toolbox';
import { AuthService, ConfigService } from '@aex/shared/root-services';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CLASSIC_PORTAL_ROUTES } from '../module/classic-portal/classic-portal.routes';
import { Location } from '@angular/common';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { isNil } from 'lodash';
import { ClassicPortalRouteMappingService } from '../module/classic-portal/services/classic-portal-route-mapping.service';
import { PortalAuthService } from '../root-services/portal-auth.service';
import { filter, finalize, retry, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ILoaderDef } from '@aex/ngx-toolbox/lib/toolbox/navigation.service';

const CLASSIC_PORTAL_MSG_DATA = {
	embeddedAccountFlow: 'embeddedAccountFlow',
	cookieAuthLogIn: 'cookieAuthLogIn',
	logOut: 'logOut',

	navManageStatusAllocation: 'navManageStatusAllocation',
	embeddedBackNavigation: 'embeddedBackNavigation',
	importPredefinedPremises: 'importPredefinedPremises',
	createNewArea: 'createNewArea',
}

@Injectable({
	providedIn: 'root',
})
export class WindowPostMessageService extends WithDestroy() implements OnDestroy {
	private readonly eventType = 'message';

	private readonly isIframeReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isIframeReadyStream: Observable<boolean> = this.isIframeReadySubject.asObservable();

	public set isIframeReady(value: boolean) {
		this.isIframeReadySubject.next(value);
	}

	public get classicPortalUrl(): string {
		return this.configService.configValues.fnoPortalBaseUrl;
	}

	private readonly isCookieAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isCookieAuthenticatedStream: Observable<boolean> = this.isCookieAuthenticatedSubject.asObservable();

	public set isCookieAuthenticated(value: boolean) {
		this.isCookieAuthenticatedSubject.next(value);
	}

	public get isCookieAuthenticated(): boolean {
		return this.isCookieAuthenticatedSubject.getValue();
	}

	public portalIframeRef: ElementRef<HTMLIFrameElement>;

	private get portalAuthService(): PortalAuthService {
		return this.authService as PortalAuthService;
	}

	public loader: ILoaderDef = {
		loader: 'inner-loader',
	}

	// note:
	// window.post message sends message to all websites
	// with matching origin (scheme/protocol, hostname/domain, port) that are listening for messages

	constructor(
		private readonly configService: ConfigService,
		private readonly router: Router,
		private readonly navigationService: NavigationService,
		private readonly authService: AuthService,
		private readonly location: Location,
		private readonly classicPortalRouteMappingService: ClassicPortalRouteMappingService,
	) {
		super();
		this.registerIframeReadyHandler();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.removeIframeReadyHandler();
	}

	public addListener(handler: (messageEvent: MessageEvent) => void): void {
		window.addEventListener(this.eventType, handler);
	}

	public removeListener(handler: (messageEvent: MessageEvent) => void): void {
		window.removeEventListener(this.eventType, handler);
	}

	public registerIframeReadyHandler(): void {
		this.addListener(this.handleIframeReadyMessages);
	}

	public removeIframeReadyHandler(): void {
		this.isIframeReady = false;
		this.removeListener(this.handleIframeReadyMessages);
	}

	public registerCookieAuthHandler(): void {
		this.addListener(this.handleCookieAuthMessages);
	}

	public removeCookieAuthHandler(): void {
		this.isCookieAuthenticated = false;
		this.removeListener(this.handleCookieAuthMessages);
	}

	public registerLoginHandler(): void {
		this.addListener(this.handleLoginMessages);
	}

	public removeLoginHandler(): void {
		this.removeListener(this.handleLoginMessages);
	}

	public registerLogoutHandler(): void {
		this.addListener(this.handleLogOutMessages);
	}

	public removeLogoutHandler(): void {
		this.removeListener(this.handleLogOutMessages);
	}

	public registerNavigationHandler(): void {
		this.addListener(this.handleNavigationMessage);
	}

	public removeNavigationHandler(): void {
		this.removeListener(this.handleNavigationMessage);
	}

	private checkValidIframeMessage(messageEvent: MessageEvent): boolean {
		return messageEvent.origin === this.classicPortalUrl;
	}

	private readonly handleIframeReadyMessages = (messageEvent: MessageEvent): void => {
		if (!this.checkValidIframeMessage(messageEvent))
			return;

		if (messageEvent.data === CLASSIC_PORTAL_MSG_DATA.embeddedAccountFlow) {
			this.isIframeReady = true;
			this.navigationService.stopLoading(this.loader);
		}
	};

	private readonly handleCookieAuthMessages = (messageEvent: MessageEvent): void => {
		if (!this.checkValidIframeMessage(messageEvent))
			return;

		if (!isNil(messageEvent.data.isAuthenticated)) {
			this.isCookieAuthenticated = (messageEvent.data.isAuthenticated === 'True');
			if (!this.isCookieAuthenticated)
				this.doClassicPortalLogin();
		}
	};

	private readonly handleLoginMessages = (messageEvent: MessageEvent): void => {
		if (!this.checkValidIframeMessage(messageEvent))
			return;

		if (messageEvent.data === CLASSIC_PORTAL_MSG_DATA.cookieAuthLogIn) {
			// this.portalIframeRef.nativeElement.contentWindow.location.reload() - not allowed to invoke method
			this.portalIframeRef.nativeElement.src = this.classicPortalRouteMappingService.getCurrentRouteUrl();
			this.navigationService.stopLoading(this.loader);
		}
	};

	private readonly handleLogOutMessages = (messageEvent: MessageEvent): void => {
		if (!this.checkValidIframeMessage(messageEvent))
			return;

		if (messageEvent.data === CLASSIC_PORTAL_MSG_DATA.logOut) {
			this.authService.logout();
			this.authService.gotoLogin();
			this.navigationService.stopLoading(this.loader);
		}
	};

	public handleNavigationMessage = (messageEvent: MessageEvent): void => {
		if (!this.checkValidIframeMessage(messageEvent))
			return;

		switch (messageEvent.data) {
			case CLASSIC_PORTAL_MSG_DATA.navManageStatusAllocation:
				this.router.navigate([CLASSIC_PORTAL_ROUTES.taskStatusAllocation.route.path]).then();
				break;
			case CLASSIC_PORTAL_MSG_DATA.embeddedBackNavigation:
				this.location.back();
				break;
			case CLASSIC_PORTAL_MSG_DATA.importPredefinedPremises: // handle redirect from embedded classic portal
				this.router.navigate([CLASSIC_PORTAL_ROUTES.clientPremisesImport.route.path]).then();
				break;
			case CLASSIC_PORTAL_MSG_DATA.createNewArea: // handle redirect from embedded classic portal
				this.router.navigate([CLASSIC_PORTAL_ROUTES.areasCreate.route.path]).then();
				break;
			default:
				break;
		}
	};

	private doClassicPortalLogin(): void {
		this.navigationService.startLoading(this.loader);

		const periodTime = 2000;
		const maxRetries = 3;
		let retryCount = 0;

		this.noZombie(interval(periodTime)).pipe(
			takeWhile(() => retryCount < maxRetries),
			tap(() => retryCount++),
			filter(() => this.isIframeReady || retryCount === maxRetries), // Only proceed if iframe is ready or max retries reached
			switchMap(() =>
				this.portalAuthService.getPortalProxyToken(this.portalIframeRef).pipe(
					retry(maxRetries),
				),
			),
			finalize(() => this.navigationService.stopLoading(this.loader)),
		).subscribe(
			() => {}, // do nothing
			error =>  {
				console.error('Failed to retrieve proxy token', error);
			},
		);
	}

}
