import { portalServer } from '@aex/shared/common-lib';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CLASSIC_PORTAL_ROUTES } from '../classic-portal.routes';

@Injectable({
	providedIn: 'root',
})
export class ClassicPortalRouteMappingService {
	private readonly iframeUrls: Record<string, string> = {
		[CLASSIC_PORTAL_ROUTES.businessRules.route.name]: 'Service/Settings',
		[CLASSIC_PORTAL_ROUTES.manageInterconnects.route.name]: 'Interconnects/Manage',
		[CLASSIC_PORTAL_ROUTES.interconnects.route.name]: 'Interconnects',
		[CLASSIC_PORTAL_ROUTES.vlans.route.name]: 'VLANs',
		[CLASSIC_PORTAL_ROUTES.products.route.name]: 'ProductAdmin',
		[CLASSIC_PORTAL_ROUTES.ontMonitoring.route.name]: 'Monitoring/OntMonitoring',
		[CLASSIC_PORTAL_ROUTES.oltMonitoring.route.name]: 'Monitoring',
		[CLASSIC_PORTAL_ROUTES.scheduler.route.name]: 'Scheduler',
		[CLASSIC_PORTAL_ROUTES.premiseManagement.route.name]: 'Area/PremiseManagement',
		[CLASSIC_PORTAL_ROUTES.predefinedPremises.route.name]: 'PredefinedPremises',
		[CLASSIC_PORTAL_ROUTES.clientPremises.route.name]: 'ClientPremises',
		[CLASSIC_PORTAL_ROUTES.clientPremisesImport.route.name]: 'ClientPremises/Import',
		[CLASSIC_PORTAL_ROUTES.areasAndComplexes.route.name]: 'Area',
		[CLASSIC_PORTAL_ROUTES.areasCreate.route.name]: 'Area/Item',
		[CLASSIC_PORTAL_ROUTES.addresses.route.name]: 'Address',
		[CLASSIC_PORTAL_ROUTES.locations.route.name]: 'Location',
		[CLASSIC_PORTAL_ROUTES.manageUsers.route.name]: 'UserAdmin',
		[CLASSIC_PORTAL_ROUTES.taskStatusAllocation.route.name]: 'WorkOrder/StatusEntity',
		[CLASSIC_PORTAL_ROUTES.billingSchemes.route.name]: 'BillingScheme',
		[CLASSIC_PORTAL_ROUTES.providerBilling.route.name]: 'BillingReport/ViewBillingReport',
		[CLASSIC_PORTAL_ROUTES.serviceChange.route.name]: 'Service/Change',
		[CLASSIC_PORTAL_ROUTES.serviceProviderChange.route.name]: 'Service/Change',
	};

	private serviceId: string;
	private customerId: string;
	private changeServiceProvider: boolean;

	private readonly EMBED_CLASSIC_PORTAL_QUERY_STRING = 'embedClassicPortal';

	constructor(
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
	) {
		this.activatedRoute.queryParamMap.subscribe(
			(paramMap: ParamMap) => {
				this.serviceId = paramMap.get('serviceId');
				this.customerId = paramMap.get('customerId');
				this.changeServiceProvider = paramMap.get('changeServiceProvider')?.toLowerCase() === 'true';
			},
		);
	}

	public getCurrentRouteUrl(): string {
		// remove leading '/'
		let currentRoute = this.router.url.startsWith('/') ? this.router.url.slice(1) : this.router.url;
		// isolate query params
		currentRoute = currentRoute.split('?')[0];
		const iframeUrl = this.iframeUrls[currentRoute] || '';

		if (iframeUrl === this.iframeUrls[CLASSIC_PORTAL_ROUTES.serviceProviderChange.route.name])
			return this.handleServiceChangeUrl(iframeUrl);
		else
			return portalServer(`${iframeUrl}?${this.EMBED_CLASSIC_PORTAL_QUERY_STRING}=true`);
	}

	private handleServiceChangeUrl(iframeUrl: string): string {
		// notes
		// portal 2 user is applying service changes for someone else, therefore include impersonateid
		// classic portal includes the entity service address id in the url
		// but the controller endpoint does not require the address id - only the service id and the customer id
		// instead of omitting the address id, used the service id in its place
		// doing so
		// not aware of an api to fetch the service entity address id
		const portalUrlFragment = this.changeServiceProvider ?
			`entityServiceId=${this.serviceId}&impersonateid=${this.customerId}` :
			`sameSP=True&entityServiceId=${this.serviceId}&impersonateid=${this.customerId}`;

		return portalServer(
			`${iframeUrl}/${this.serviceId}?${portalUrlFragment}&${this.EMBED_CLASSIC_PORTAL_QUERY_STRING}=true`,
		);
	}
}
