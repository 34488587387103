import { Component } from '@angular/core';
import { svgImageHeight, svgImageWidth } from '../../shared/constant';
import { CustomerService } from '@aex/shared/apis';
import { CustomerUserService, ICustomer } from '@aex/shared/common-lib';
import { ToastrService } from 'ngx-toastr';
import { WithDestroy } from '@aex/ngx-toolbox';

@Component({
	selector: 'app-manage-profile',
	templateUrl: './manage-profile.component.html',
	styleUrls: ['./manage-profile.component.scss'],
})
export class ManageProfileComponent extends WithDestroy() {
	public isEditMode: boolean = false;
	public readonly errorIcon: string = 'assets/img/portal/error-info.svg';
	public readonly imageWidth: string = svgImageWidth;
	public readonly imageHeight: string = svgImageHeight;
	public emailNotConfirmed: boolean = true;
	public customer: ICustomer;

	constructor(private readonly customerUserService: CustomerUserService, private readonly customerService: CustomerService, private readonly toastrService: ToastrService) {
		super();
		this.noZombie(this.customerUserService.currentUserStream).subscribe((user) => {
			this.customer = {
				...user,
				password: '',
				confirm_password: '',
			};
		});
	}

	public toggleEditMode(): void {
		this.isEditMode = !this.isEditMode;
	}

	public cancelFormEdit(): void {
		this.customer = this.customerUserService.currentUser;
		this.toggleEditMode();
	}

	public updateProfile(): void {
		if (this.customer.password !== this.customer.confirm_password)
			this.toastrService.error('Passwords do not match');
		else {
			const data: Partial<ICustomer> = {
				first_name: this.customer.first_name,
				last_name: this.customer.last_name,
				mobile_number: this.customer.mobile_number,
				email: this.customer.email,
			};
			if (this.customer.password)
				data.password = this.customer.password;

			this.customerService.updateCustomer(this.customer.id, data).subscribe((user) => {
				this.toastrService.success('Your profile has been updated.');
				this.toggleEditMode();
				this.customerUserService.currentUser = {
					...user,
					password: '',
					confirm_password: '',
				};
			});
		}
	}

	public resendEmail(): void {
		this.customerService.sendVerificationEmail(this.customer.email).subscribe((emailSent) => {
			if (emailSent)
				this.toastrService.success('Verification email has been sent.');
		});
	}
}
