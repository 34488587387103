<form #profileForm="ngForm" [mustMatch]="['password', 'confirmPassword']" class="manage-profile" (ngSubmit)="updateProfile()" >
	<div class="header">
		<h2 class="heading">Manage Profile</h2>
		<div *ngIf="!isEditMode">
			<button (click)="toggleEditMode()" class="base-button">EDIT</button>
		</div>
		<div *ngIf="isEditMode" class="flex gap-4">
			<button (click)="cancelFormEdit()" class="btn-cancel base-button">CANCEL</button>
			<button type="submit" class="primary-button">SAVE</button>
		</div>
	</div>
	<div *ngIf="emailNotConfirmed" class="email-verification-error-container">
		<span>Your e-mail address is not confirmed.</span>
		<ul class="email-verification-error-list">
			<li>Please check your junk mail. If you have not received it in 10 minutes, resend the e-mail. <a (click)="resendEmail()">Resend e-mail</a>.</li>
		</ul>
	</div>
	<div class="profile-form">
		<div class="portal-textbox">
			<kendo-formfield>
				<kendo-label text="First Name (Required)"></kendo-label>
				<kendo-textbox [(ngModel)]="customer.first_name" [disabled]="!isEditMode" name="first_name" placeholder="Enter First Name" required></kendo-textbox>
			</kendo-formfield>
		</div>
		<div class="portal-textbox">
			<kendo-formfield>
				<kendo-label text="Surname (Required)"></kendo-label>
				<kendo-textbox [(ngModel)]="customer.last_name" [disabled]="!isEditMode" name="last_name" placeholder="Enter Surname" required></kendo-textbox>
			</kendo-formfield>
		</div>
		<div class="portal-textbox">
			<kendo-formfield>
				<kendo-label text="Email Address (Required)"></kendo-label>
				<kendo-textbox
					#emailInput="ngModel"
					[(ngModel)]="customer.email"
					[clearButton]="true"
					[disabled]="!isEditMode"
					[email]="true"
					[ngClass]="{ 'invalid-field': emailNotConfirmed }"
					name="email"
					placeholder="Enter Email Address"
					required
				>
					<ng-template kendoTextBoxSuffixTemplate>
						<img [height]="imageHeight" [ngSrc]="errorIcon" [width]="imageWidth" alt="info-black-icon" class="mr-4" />
					</ng-template>
				</kendo-textbox>

				<small *ngIf="!emailInput.invalid || !emailInput.touched; else invalidEmail" class="text-alerts-danger-text-v1 text-2xs"> Not confirmed. </small>
				<ng-template #invalidEmail>
					<div>
						<kendo-formerror>Please enter a valid email address</kendo-formerror>
					</div>
				</ng-template>
			</kendo-formfield>
		</div>
		<div class="portal-textbox">
			<kendo-formfield>
				<kendo-label text="Contact Number (Required)"></kendo-label>
				<kendo-textbox
					[(ngModel)]="customer.mobile_number"
					[appNumericOnly]="true"
					[disabled]="!isEditMode"
					name="mobile_number"
					placeholder="Enter Contact Number"
					required
				></kendo-textbox>
			</kendo-formfield>
		</div>
		<div class="portal-textbox">
			<kendo-formfield>
				<kendo-label text="New Password (Required)"></kendo-label>
				<kendo-textbox
					#passwordInput="ngModel"
					[(ngModel)]="customer.password"
					[disabled]="!isEditMode"
					appPasswordValidator
					name="password"
					placeholder="Enter New Password"
				></kendo-textbox>
				<div *ngIf="passwordInput.invalid && passwordInput.touched">
					<kendo-formerror *ngIf="passwordInput.errors?.minlength">Password must be at least 8 characters</kendo-formerror>
					<kendo-formerror *ngIf="passwordInput.errors?.lowercase">At least one lower case character</kendo-formerror>
					<kendo-formerror *ngIf="passwordInput.errors?.uppercase">At least one upper case character</kendo-formerror>
					<kendo-formerror *ngIf="passwordInput.errors?.specialChar">At least one special character</kendo-formerror>
				</div>
			</kendo-formfield>
		</div>
		<div class="portal-textbox">
			<kendo-formfield>
				<kendo-label text="Confirm Password (Required)"></kendo-label>
				<kendo-textbox
					#confirmPasswordInput="ngModel"
					[(ngModel)]="customer.confirm_password"
					[disabled]="!isEditMode"
					name="confirm_password"
					placeholder="Enter Confirm Password"
				>
				</kendo-textbox>
				<div *ngIf="confirmPasswordInput.invalid && confirmPasswordInput.touched">
					<kendo-formerror *ngIf="confirmPasswordInput.errors['mustMatch']">Passwords must match</kendo-formerror>
				</div>
			</kendo-formfield>
		</div>
	</div>
</form>
