<div class="view-dialog" data-testid="view-dialog">
	<ng-container *ngIf="editSaveStateStream | async as state">
		<p *ngIf="state.canEdit && state.canSave">You have unsaved changes. Update the current View or save a new one before closing.</p>
		<p *ngIf="state.canSave && !state.canEdit">You have unsaved changes. Save a new View before closing.</p>
		<p>If you don't save, your changes will be lost</p>
	</ng-container>
</div>
<kendo-dialog-actions>
	<button (click)="handleAction('DONT_SAVE')" class="py-app-size-0-5" data-testid="view-dialog-cancel-button" kendoButton>DON'T SAVE</button>
	<button (click)="handleAction('CANCEL')" class="py-app-size-0-5" kendoButton>CANCEL</button>
	<button (click)="handleAction('UPDATE')" *ngIf="(canEdit | async) && (canSave | async)" class="py-app-size-0-5" kendoButton>UPDATE</button>
	<button (click)="handleAction('SAVE')" class="primary-button" data-testid="save-view-button" kendoButton>SAVE VIEW</button>
</kendo-dialog-actions>
