<div kendoTooltip position="top">
	<div class="user-menu-container" [title]='manageProfileDescription' data-testid="user-menu-container">
		<img [height]="imageHeight" [ngSrc]="profileLogo" [width]="imageWidth" alt="profile-logo" class="profile-logo" data-testid="user-menu-logo" />
		<div class="info">
			<p class="name">
				{{ profileName }}
			</p>
			<a [routerLink]="[profileLink]" class="link">{{manageProfileDescription}}</a>
		</div>
	</div>
</div>
<kendo-dropdownlist
	[data]="areaList"
	[popupSettings]="{
		popupClass: 'profile-dropdown-popup'
	}"
	[showStickyHeader]="false"
	[value]="areaList?.[0]?.items?.[0]"
	data-testid="fno-dropdown-list"
	textField="name"
	valueField="name"
></kendo-dropdownlist>
<button (click)="initiateLogOut()" class="btn-logout" data-testid="log-out-button">LOG OUT</button>
<div kendoDialogContainer></div>

<iframe #portalIframeLogout title="classic portal" id="authPortalIframe" [src]="trustedUrl | safe">
</iframe>
