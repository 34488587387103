import { sisenseReportUrl } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { Component } from '@angular/core';

@Component({
	selector: 'app-automated-insights-report',
	templateUrl: './automated-insights-report.component.html',
	styleUrls: ['./automated-insights-report.component.scss'],
})
export class AutomatedInsightsComponent {

	public sisenseReportIframeUrl: string;

	constructor(
		private readonly configService: ConfigService,
	) {
		const sisenseToken = this.configService.configValues.sisenseReportToken;
		this.sisenseReportIframeUrl = sisenseReportUrl(`app/main/dashboards/${sisenseToken}?embed=true&r=true`);
	}

}
